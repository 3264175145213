import Cleave from 'cleave.js/react'
import { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
// I was getting an error loading the .au library.
// Bizarely, it was working well when using ".AU"
// but this was causing this warning: There are multiple modules with names that only differ in casing.
// Using the full i18n but this is not the best solution due to the size of the file
import 'cleave.js/dist/addons/cleave-phone.i18n'
import moment from 'moment-timezone'
import { Calendar } from 'primereact/calendar'
import { classNames } from 'primereact/utils'
import { useVideoCompression } from '../FileValidations'
import { parameterize, range } from './utils'

export const Label = ({ label, required }) => (
  <label className="form-control-label text optional">
    {label} {required && <abbr title="required">*</abbr>}
  </label>
)

const Input = ({ label, wrapperClass = 'form-group', required, ...props }) => {
  return (
    <div className={wrapperClass}>
      {label && <Label required={required} label={label} />}
      <input className="form-control string required" required={required} name={label} {...props} />
    </div>
  )
}

const PhoneInput = ({ label, wrapperClass = 'form-group', required, hint, ...props }) => {
  return (
    <div className={wrapperClass}>
      {label && <Label required={required} label={label} />}
      <Cleave
        options={{ phone: true, phoneRegionCode: 'AU', swapHiddenInput: true }}
        className="form-control string required"
        required={required}
        {...props}
      />
      {hint && <small className="text-secondary">{hint}</small>}
    </div>
  )
}

const DateInput = ({ label, wrapperClass = 'form-group', required, hint, onChange, ...props }) => {
  return (
    <div className={wrapperClass}>
      {label && <Label required={required} label={label} />}
      <div>
        <Calendar
          dateFormat="dd/mm/yy"
          {...props}
          value={moment.tz(props.value, 'Australia/Brisbane').toDate()}
          className="w-100"
          onChange={(event) => {
            const aestDate = moment.tz(event.target.value, 'Australia/Brisbane')
            const formattedDate = aestDate.format('YYYY-MM-DDTHH:mm:ssZ')
            onChange(formattedDate)
          }}
        />
      </div>
    </div>
  )
}

const TextArea = ({ label, ...props }) => {
  return (
    <div className="form-group">
      <label className="form-control-label text optional">{label}</label>
      <textarea className="form-control string required" {...props} />
    </div>
  )
}

const Checkbox = ({
  label,
  id,
  wrapperStyle = {},
  wrapperClass = 'form-group',
  value,
  ...props
}) => {
  return (
    <div className={wrapperClass} style={wrapperStyle}>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input required"
          {...props}
          defaultChecked={value}
          id={id || parameterize(label)}
        />
        <label className="form-control-label text optional" htmlFor={id || parameterize(label)}>
          {label}
        </label>
      </div>
    </div>
  )
}

const Select = ({ wrapperClass = 'form-group', label, required, hint, ...props }) => {
  return (
    <div className={wrapperClass}>
      {label && <Label required={required} label={label} />}
      <ReactSelect
        {...props}
        required={required}
        isClearable
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
      />
      {hint && <small className="text-secondary">{hint}</small>}
    </div>
  )
}

// ID is required for the switch to work
const Switch = ({ value, className = '', label, onChange, id, isDisabled = false }) => {
  return (
    <div className={classNames('custom-control custom-switch mr-3', className)}>
      <input
        type="checkbox"
        className="custom-control-input"
        checked={value}
        onChange={onChange}
        id={id}
        disabled={isDisabled}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

const Errors = ({ errors }) => {
  if (!errors || Object.keys(errors).length === 0) {
    return null
  }

  return (
    <div className="border border-danger rounded p-2 mb-2">
      <ul className="mb-0">
        {Object.keys(errors).map((field) => (
          <li>
            <b>{field}</b>: {errors[field].join(', ')}
          </li>
        ))}
      </ul>
    </div>
  )
}

let CurrencyInput = ({ name, label, placeholder, hint, ...fields }) => (
  <div className="form-group">
    {(label || placeholder) && <label htmlFor={name}>{label || placeholder}</label>}
    <Cleave
      options={{
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        prefix: '$',
        swapHiddenInput: true,
        rawValueTrimPrefix: true,
      }}
      className="form-control"
      name={name}
      placeholder={placeholder}
      {...fields}
    />
    {hint && <small className="text-secondary">{hint()}</small>}
  </div>
)

let NumberInput = ({ name, label, placeholder, ...fields }) => (
  <div className="form-group">
    {(label || placeholder) && <label htmlFor={name}>{label || placeholder}</label>}
    <Cleave
      options={{
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        swapHiddenInput: true,
        rawValueTrimPrefix: true,
      }}
      className="form-control"
      name={name}
      placeholder={placeholder}
      {...fields}
    />
  </div>
)

const ManufacturerSelect = ({ manufacturer, setManufacturer }) => {
  let [manufacturerOptions, setManufacturerOptions] = useState()

  useEffect(() => {
    if (manufacturerOptions) {
      return
    }
    fetch('/manufacturers.json')
      .then((res) => res.json())
      .then((data) => {
        let options = data.map((man) => {
          return { value: man.id, label: man.name }
        })
        setManufacturerOptions(options)
      })
  })

  return (
    <Select
      label="Make"
      options={manufacturerOptions}
      onChange={(e) => setManufacturer(e.value)}
      value={manufacturerOptions && manufacturerOptions.filter((o) => o.value == manufacturer)}
      placeholder="Make..."
      className="manufacturer-select"
      menuPortalTarget={document.body}
    />
  )
}

const VehicleBuilder = ({
  manufacturer,
  setManufacturer,
  family,
  setFamily,
  year,
  setYear,
  defaultFamilyId,
  showBadge = false,
  defaultYear,
  bodyconfiguration,
  wheelbaseconfiguration,
  setWheelbaseconfiguration,
  setBodyconfiguration,
  modelYear,
  setModelYear,
  badge,
  setBadge,
  colourOptions,
  colour,
  setColour,
  badgeHint,
}) => {
  let [yearOptions, setYearOptions] = useState()
  let [badgeOptions, setBadgeOptions] = useState()
  let [families, setFamilies] = useState()
  let [bodyconfigurationOptions, setBodyconfigurationOptions] = useState()
  let [wheelbaseconfigurationOptions, setWheelbaseconfigurationOptions] = useState()
  let [familyOptions, setFamilyOptions] = useState()
  let [modelYearOptions, setModelYearOptions] = useState()

  let families_url = `/manufacturers/${manufacturer}/families.json`

  useEffect(() => {
    setFamily(defaultFamilyId || null)
    setYear(defaultYear || null)
    setYearOptions(null)
    if (manufacturer) {
      fetch(families_url)
        .then((res) => res.json())
        .then((res) => {
          setFamilies(res)
          setFamilyOptions(
            res.map((m) => {
              return { value: m.id, label: m.name }
            })
          )
        })
    }
  }, [manufacturer])

  useEffect(() => {
    setYear(defaultYear || null)
    if (family && families) {
      let current_family = families.filter((f) => f.id == family)[0]
      if (!current_family) {
        return
      }
      let years = range(current_family.year_start, current_family.year_end, 1)
      setYearOptions(
        years
          .map((y) => {
            return { value: y, label: y }
          })
          .reverse()
      )
      let newBadgeOptions = current_family.badges.map((b) => {
        return { value: b, label: b }
      })
      setBadgeOptions([{ value: '', label: 'Select an option...' }, ...newBadgeOptions])
      setBodyconfigurationOptions(
        current_family.bodyconfigurations.map((b) => {
          return { value: b, label: b }
        })
      )
      setWheelbaseconfigurationOptions(
        current_family.wheelbaseconfigurations.map((b) => {
          return { value: b, label: b }
        })
      )
      setModelYearOptions(
        current_family.modelyears.map((b) => {
          return { value: b, label: b }
        })
      )
    }
  }, [family, families])

  return (
    <>
      <div className="form-group">
        <ManufacturerSelect manufacturer={manufacturer} setManufacturer={setManufacturer} />
      </div>
      <div className="form-group">
        <Select
          label="Model"
          options={familyOptions}
          isDisabled={!familyOptions}
          value={familyOptions && familyOptions.filter((o) => o.value === family)}
          onChange={(e) => setFamily(e.value)}
          placeholder="Model..."
          className="family-select"
          menuPortalTarget={document.body}
        />
      </div>
      <div className="form-group">
        <Select
          label="Year"
          isDisabled={!yearOptions}
          options={yearOptions}
          value={yearOptions && yearOptions.filter((o) => o.value === year)}
          onChange={(e) => setYear(e.value)}
          placeholder="Year..."
          className="year-select"
          menuPortalTarget={document.body}
        />
      </div>
      {showBadge && (
        <>
          <div className="form-group">
            <Select
              label="Badge"
              isDisabled={!badgeOptions}
              options={badgeOptions}
              value={badgeOptions && badgeOptions.filter((o) => o.value === badge)}
              onChange={(e) => setBadge(e.value)}
              placeholder="Badge..."
              hint={badgeHint}
              menuPortalTarget={document.body}
            />
          </div>
          {bodyconfigurationOptions && bodyconfigurationOptions.length > 1 && (
            <div className="form-group">
              <Select
                label="Body Configuration"
                isDisabled={!bodyconfigurationOptions}
                options={bodyconfigurationOptions}
                value={
                  bodyconfigurationOptions &&
                  bodyconfigurationOptions.filter((o) => o.value === bodyconfiguration)
                }
                onChange={(e) => setBodyconfiguration(e.value)}
                placeholder="Body configuration..."
                menuPortalTarget={document.body}
              />
            </div>
          )}
          {wheelbaseconfigurationOptions && wheelbaseconfigurationOptions.length > 1 && (
            <div className="form-group">
              <Select
                label="Wheelbase Configuration"
                isDisabled={!wheelbaseconfigurationOptions}
                options={wheelbaseconfigurationOptions}
                value={
                  wheelbaseconfigurationOptions &&
                  wheelbaseconfigurationOptions.filter((o) => o.value === wheelbaseconfiguration)
                }
                onChange={(e) => setWheelbaseconfiguration(e.value)}
                placeholder="Wheelbase configuration..."
                menuPortalTarget={document.body}
              />
            </div>
          )}
          {modelYearOptions && modelYearOptions.length > 1 && (
            <div className="form-group">
              <Select
                label="Model Year"
                isDisabled={!modelYearOptions}
                options={modelYearOptions}
                value={modelYearOptions && modelYearOptions.filter((o) => o.value === modelYear)}
                onChange={(e) => setModelYear(e.value)}
                placeholder="Model year..."
                menuPortalTarget={document.body}
              />
            </div>
          )}
          <div className="form-group">
            <Select
              label="Colour"
              isDisabled={!colourOptions}
              options={colourOptions}
              value={colourOptions && colourOptions.filter((o) => o.value === colour)}
              onChange={(e) => setColour(e.value)}
              placeholder="Colour..."
              menuPortalTarget={document.body}
            />
          </div>
        </>
      )}
    </>
  )
}

const FileInput = ({ label, wrapperClass = 'form-group', required, ...props }) => {
  const { optimizeFile } = useVideoCompression()
  const handleFileChange = async (e) => {
    const fileList = e.target.files[0]
    if (fileList) {
      const processFile = async ({ file, index }, i) => {
        try {
          // call optimizer with abort controller
          const { file: optimizedFile } = await optimizeFile(file, controller)
          const blobUrl = URL.createObjectURL(optimizedFile)
          const updatedUrl = fileState?.[i]?.url ?? `${blobUrl}?type=${file.type}`

          return { file: optimizedFile, url: updatedUrl, index }
        } catch (err) {
          // FFMPEG doesn't close instantly so it can try to exec the next read
          if (controller?.signal?.aborted && err.message.includes('file')) {
            return { file, error: err?.message }
          }
          return { file, error: err?.message }
        }
      }

      const updatedFile = processFile(fileList)
      upload(updatedFile)
    }
  }

  const upload = async (file) => {
    if (file) {
      setLoading(true)
      const cloudinaryResponse = await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('image', file)

        const xhr = new XMLHttpRequest()
        xhr.open('POST', imageEndpoint)
        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              // File was uploaded successfully
              const response = JSON.parse(xhr.responseText)
              const previewFile = response.file
              resolve(previewFile)
            } else {
              // Handle error
              reject(new Error('Error uploading file: ' + xhr.status))
            }
          }
        }
        xhr.send(formData)
      })
      setLoading(false)
      console.log('Cloudinary response: ', cloudinaryResponse.url)
      if (cloudinaryResponse) {
        if (customOnChange) {
          customOnChange({
            file,
            url: cloudinaryResponse.url,
            width: cloudinaryResponse.width,
            height: cloudinaryResponse.height,
          })
        } else {
          updateItem({
            ...item,
            [itemName]: {
              file,
              url: cloudinaryResponse.url,
              width: cloudinaryResponse.width,
              height: cloudinaryResponse.height,
            },
          })
        }
        alert('Image successfully uploaded to Cloudinary: ' + cloudinaryResponse.url)
      } else {
        alert('An error occured when uploading the image')
      }
    } else {
      alert('There is no file to upload')
    }
  }

  return (
    <div className={wrapperClass}>
      {label && <Label required={required} label={label} />}
      <input
        type="file"
        onChange={handleFileChange}
        className="form-control string required"
        required={required}
        name={label}
        {...props}
      />
    </div>
  )
}

export {
  Checkbox,
  CurrencyInput,
  DateInput,
  Errors,
  FileInput,
  Input,
  ManufacturerSelect,
  NumberInput,
  PhoneInput,
  Select,
  Switch,
  TextArea,
  VehicleBuilder,
}
